import React from "react";
import "./Cards.css";
import CardItem from "./CardItem";

function CardsUsluge() {
  return (
    <>
      <div className="cards">
        <h1>Nudimo kompletan servis i održavanje Vašeg automobila</h1>
        <div className="cards__container">
          <div className="cards__wrapper">
            <ul className="cards__items">
              <CardItem
                src="images/dijagnostika.jpg"
                text="Dijagnostika je prvi korak potreban za efikasnu popravku Vašeg automobila"
                label="usluge"
                path="/usluge"
              />
              <CardItem
                src="images/maliservis.jpg"
                text="Mali servis u redovnim intervalima je najefikasniji način održavanja vozila"
                label="usluge"
                path="/usluge"
              />
            </ul>
            <ul className="cards__items">
              <CardItem
                src="images/velikiservis.jpg"
                text="Veliki servis će dugoročno obezbediti sigurnost Vašeg vozila"
                label="usluge"
                path="/usluge"
              />
              <CardItem
                src="images/vulkanizer.jpg"
                text="Vulkanizer - kod nas možete kupiti, zameniti i održavati vaše pneumatike"
                label="usluge"
                path="/usluge"
              />
            </ul>
            <ul className="cards__items">
              <CardItem
                src="images/hotelguma.jpg"
                text="Hotel guma - Vaši pneumatici će biti spremni za sledeću sezonu"
                label="usluge"
                path="/usluge"
              />
              <CardItem
                src="images/servisklime.jpg"
                text="Servis auto klime - održavanje i popravka najsavremenijom opremom i tehnikama"
                label="usluge"
                path="/usluge"
              />
            </ul>
            <ul className="cards__items">
              <CardItem
                src="images/servisakumulatora.jpg"
                text="Servis akumulatora - proverite stepen napunjenosti i pokaznu snagu akumulatora"
                label="usluge"
                path="/usluge"
              />
              <CardItem
                src="images/prodajaakumulatora.jpg"
                text="Prodaja i otkup akumulatora - pomoći ćemo Vam u odabiru i ugradnji akumulatora iz naše ponude"
                label="usluge"
                path="/usluge"
              />
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default CardsUsluge;
