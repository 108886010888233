import React from "react";
import "./Cards.css";
import CardItemKontakt from "./CardItemKontakt";

function CardsKontakt() {
  return (
    <>
      <div className="cards">
        <h1>KONTAKT I KAKO DO NAS</h1>
        <div className="cards__container">
          <div className="cards__wrapper">
            <ul className="cards__items">
              <CardItemKontakt
                src="images/kontakt1.jpg"
                label="kontakt"
                path="/kontakt"
              />
            </ul>
          </div>
          <div className="responsive-map-container">
            <iframe
              title="Google map - kako do nas"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11321.013767324033!2d20.5019397!3d44.8164012!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a7b6f684a0241%3A0xcf6bd9c63d85b6f5!2sKM%20auto%20centar!5e0!3m2!1sen!2srs!4v1617745732906!5m2!1sen!2srs"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
}

export default CardsKontakt;
