import React from "react";
import "./Cards.css";
import CardItem from "./CardItem";
import follow from "../images/follow.jpg";

function Cards() {
  return (
    <div className="cards">
      <section className="s2">
        <div className="main-container">
          <div className="about-wrapper">
            <div className="about-me">
              <h3>KM auto centar Vam nudi</h3>
              <br />
              <p>
                U našem servisu možete uraditi kompletnu dijagnostiku, mali i
                veliki servis, servis klime i akumulatora. Takođe, nudimo i
                vulkanizerske usluge sa hotelom za gume.
              </p>
              <br />
              <p>
                Dok čekate možete popiti piće u prijatnom ambijentu našeg
                servisa.
              </p>
              <br />
              <hr />
              <br />
              <h3>Pružamo usluge za sve tipove vozila</h3>
              <br />
              <div id="skills">
                <ul>
                  <li>Dijagnostika</li>
                  <li>Mali servis</li>
                  <li>Veliki servis</li>
                  <li>Vulkanizer</li>
                </ul>
                <ul>
                  <li>Hotel guma</li>
                  <li>Servis auto klime</li>
                  <li>Servis akumulatora</li>
                  <li>Prodaja i otkup akumulatora</li>
                </ul>
              </div>
              <br />
              <div className="tel-link">
                <h4>
                  <a href="tel:+381652585858" id="tel-link">
                    <i className="fas fa-phone" /> - Zakažite pregled Vašeg
                    vozila
                  </a>
                </h4>
              </div>
            </div>

            <div className="social-links">
              <img id="social_img" src={follow} alt="slika" />
            </div>
          </div>
        </div>
      </section>

      <div className="cards__container">
        <div className="cards__wrapper">
          <CardItem
            src="images/onama.jpg"
            text="O nama"
            label="o nama"
            path="/o-nama"
          />
          <br />
          <ul className="cards__items">
            <CardItem
              src="images/usluge.jpg"
              text="Pogledajte detaljnu ponudu"
              label="usluge"
              path="/usluge"
            />
            <CardItem
              src="images/kontakt.jpg"
              text="Kako do nas"
              label="kontakt"
              path="/kontakt"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
