import React from "react";
import "../../App.css";
import CardsONama from "../CardsONama";

export default function ONama() {
  return (
    <>
      <CardsONama />
    </>
  );
}
