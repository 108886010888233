import React from "react";
import { Link } from "react-router-dom";

function CardItemOnama(props) {
  return (
    <>
      <li className="cards__item">
        <Link className="cards__item__link" to={props.path}>
          <figure className="cards__item__pic-wrap" data-category={props.label}>
            <img
              className="cards__item__img"
              src={props.src}
              alt="Fotografija"
            />
          </figure>
          <div className="cards__item__info">
            <h3 className="cards__item__text">{props.text}</h3>
          </div>
          <div className="cards__item__info" style={{ borderTop: "none" }}>
            <p className="cards__item__text">
              Osnivanje KM auto centra je rezultat više generacijskog bavljenjem
              auto-mehanikom i automobilizmom uopšte. Posvećenost, upornost,
              ogromno iskustvo i prikupljeno znanje doprineli su sticanju
              velikog broja stalnih klijenata, kao i konstantnom prilivu novih.
              Zadovoljstvo klijenata je najbolji pokazatelj kvaliteta i
              efikasnosti usluga koje možete dobiti u KM auto centru. Znanje i
              iskustvo naših mehaničara u kombinaciji sa najsavremenim alatom i
              opremom obezbediće ispravnosti i sigurnost Vašeg vozila. Vaše je
              samo da dođete i prepustite sve nama, a mi ćemo se pobrinuti da
              Vaše vozilo dobije najbolji mogući tretman.
            </p>
          </div>
        </Link>
      </li>
    </>
  );
}

export default CardItemOnama;
