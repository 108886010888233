import React from "react";
import "../../App.css";
import CardsKontakt from "../CardsKontakt";

export default function Kontakt() {
  return (
    <>
      <CardsKontakt />
    </>
  );
}
