import React from "react";
import { Link } from "react-router-dom";

function CardItemKontakt(props) {
  return (
    <>
      <li className="cards__item">
        <Link className="cards__item__link" to={props.path}>
          <figure className="cards__item__pic-wrap" data-category={props.label}>
            <img
              className="cards__item__img"
              src={props.src}
              alt="Fotografija"
            />
          </figure>
          <div className="cards__item__info">
            <h3 className="cards__item__text">Pribojska 44 a, Beograd</h3>
            <h3 className="cards__item__text">
              <a href="tel:+381652585858">
                <i className="fas fa-mobile-alt" /> +381652585858
              </a>{" "}
              <a href="tel:+381114527202">
                <i className="fas fa-phone" /> +381114527202
              </a>
            </h3>
            <h3 className="cards__item__text">kmautocentar@gmail.com </h3>
            <h3 className="cards__item__text">
              Radno vreme: ponedeljak - subota od 9 do 17
            </h3>
          </div>
        </Link>
      </li>
    </>
  );
}

export default CardItemKontakt;
