import React from "react";
import "../../App.css";
import CardsUsluge from "../CardsUsluge";

export default function Usluge() {
  return (
    <>
      <CardsUsluge />
    </>
  );
}
