import React from "react";
import "../App.css";
// import { Button } from "./Button";
import "./HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-container">
      <video id="videoBG" poster="/images/postergif1.gif" autoPlay loop muted>
        <source src="/videos/video-3.mp4" type="video/mp4" />
      </video>
      <h1>KM auto centar</h1>
      <p>kompletan servis Vašeg automobila</p>
      {/* <div className="hero-btns">
        <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
        >
          Ponuda
        </Button>
        <Button
          className="btns"
          buttonStyle="btn--primary"
          buttonSize="btn--large"
        >
          Kontaktirajete nas
        </Button>
      </div> */}
    </div>
  );
}

export default HeroSection;
