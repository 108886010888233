import React from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/pages/Home";
import Usluge from "./components/pages/Usluge";
import ONama from "./components/pages/ONama";
import Kontakt from "./components/pages/Kontakt";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/usluge" exact component={Usluge} />
          <Route path="/o-nama" exact component={ONama} />
          <Route path="/kontakt" exact component={Kontakt} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
