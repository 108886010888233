import React from "react";
import "./Cards.css";
import CardItemOnama from "./CardItemOnama";

function CardsONama() {
  return (
    <>
      <div className="cards">
        <h1>Više o KM auto centru</h1>
        <div className="cards__container">
          <div className="cards__wrapper">
            <ul className="cards__items">
              <CardItemOnama
                src="images/onama-section.jpg"
                text="Vaša bezbednost i vrhunski kvalitet usluga su naši prioriteti"
                label="o nama"
                path="/o-nama"
              />
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default CardsONama;
